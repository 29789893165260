<template>
  <v-dialog v-model="dialog" width="550">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        style="width: 100% !important"
        v-on="on"
        v-text="$t($t('edit', { name: '' }))"
        @click="fetch"
        elevation="0"
      />
    </template>
    <v-card id="tattoo_type"
      ><v-card-title
        v-text="$t('tattooers.style')"
        style="text-decoration: underline; text-transform: uppercase"
      ></v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <td class="color" style="text-align: center">Estilo</td>
            <td class="color" style="text-align: center">
              <v-icon color="primary" small class="mr-1">$clip</v-icon>Foto
            </td>
            <td class="color" style="text-align: center">Descripción</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(type, i) in types" :key="i">
            <td>
              <v-combobox
                :items="types_tattooers"
                v-model="type.name"
                class="colorBarrita"
              ></v-combobox>
            </td>
            <td>
              <v-file-input
                prepend-icon=""
                v-model="type.new_file"
                :placeholder="clearName(type.url)"
                @click.stop
                class="colorBarrita pointer"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </td>
            <td>
              <v-menu
                v-model="type.menu"
                transition="scale-transition"
                offset-y
                open-on-focus
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="type.description"
                    class="colorBarrita"
                    v-on="on"
                  ></v-text-field>
                </template>
                <textarea
                  outlined
                  :ref="`textarea-${i}`"
                  autofocus
                  class="pa-2"
                  style="width: 300px; height: 100px; color: white"
                  v-model="type.description"
                  color="primary"
                >
                </textarea>
              </v-menu>
            </td>
            <td>
              <v-btn icon @click="del(i)" small>
                <v-icon color="primary" small="">$delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <v-btn fab x-small outlined @click="add">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="
            dialog = false;
            $emit('update');
            fetch();
          "
          outlined
          style="height: 30px; width: 100px"
          >{{ $t("cancel", { name: "" }) }}</v-btn
        >

        <v-btn
          @click="save"
          style="height: 30px; width: 100px !important"
          elevation="0"
        >
          <v-icon small style="margin-right: 5px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooTypes",
  data() {
    return {
      dialog: false,
      menu: [false],
      types: [{}],
      types_tattooers: [
        "Trash polka",
        "Fineline",
        "Realismo",
        "Realismo a color",
        "Blackwork",
        "Neotradi",
        "Tradicional",
        "Watercolor",
        "Kawai",
        "Mahori",
        "Geométrico",
        "Mandalas",
      ],
    };
  },
  props: ["tattooer_id", "list"],
  mounted() {
    this.fetch();
  },

  methods: {
    add() {
      this.types.push({});
      this.menu.push(false);
    },
    del(i) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() => {
        this.types.splice(i, 1);
        this.menu.splice(i, 1);
      });
    },
    open(i) {
      this.menu[i] = true;
      console.log("open");
      /*setTimeout(() => {
        this.$refs[`textarea-${i}`].focus;
        //console.log("focus");
      }, 1000);*/
    },
    ...mapActions("tattooers", [
      "getTattooerTattooTypes",
      "editTattooerTattooTypes",
    ]),
    clearName(name) {
      if (name) {
        name = name.split("/");
        return name[name.length - 1];
      }
    },
    fetch() {
      this.getTattooerTattooTypes(this.tattooer_id).then((data) => {
        this.types = data.map((x) => {
          return { ...x, menu: false };
        });
        this.menu = data.map((x) => false);
      });
    },
    save() {
      let formData = new FormData();
      this.types.forEach((type, i) => {
        if (type.id) formData.append("types[" + i + "][id]", type.id);
        formData.append("types[" + i + "][title]", type.name);
        formData.append("types[" + i + "][description]", type.description);
        if (type.new_file)
          formData.append("types[" + i + "][file]", type.new_file);
        formData.append("types[" + i + "][old_file]", type.url);
      });
      this.editTattooerTattooTypes({
        tattooer_id: this.tattooer_id,
        formData,
      }).then((data) => {
        this.types = data;
        this.$alert("Guardado correctamente");
        this.dialog = false;
      });
    },
  },
};
</script>

<style lang="sass">
#tattoo_type
  .v-text-field__slot
    max-width: 100%
  .colorBarrita
    width: 100px
    margin: 0 auto
    input,
    .v-file-input__text--placeholder
      text-align: center
    .v-input__append-inner
      display: none
</style>