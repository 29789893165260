var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.list)?_c('v-btn',_vm._g({staticStyle:{"width":"100% !important"},attrs:{"elevation":"0"},domProps:{"textContent":_vm._s(_vm.$t(_vm.$t('edit', { name: '' })))},on:{"click":_vm.fetch}},on)):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"tattoo_type"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"},domProps:{"textContent":_vm._s(_vm.$t('tattooers.style'))}}),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{staticClass:"color",staticStyle:{"text-align":"center"}},[_vm._v("Estilo")]),_c('td',{staticClass:"color",staticStyle:{"text-align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""}},[_vm._v("$clip")]),_vm._v("Foto ")],1),_c('td',{staticClass:"color",staticStyle:{"text-align":"center"}},[_vm._v("Descripción")]),_c('td')])]),_c('tbody',_vm._l((_vm.types),function(type,i){return _c('tr',{key:i},[_c('td',[_c('v-combobox',{staticClass:"colorBarrita",attrs:{"items":_vm.types_tattooers},model:{value:(type.name),callback:function ($$v) {_vm.$set(type, "name", $$v)},expression:"type.name"}})],1),_c('td',[_c('v-file-input',{staticClass:"colorBarrita pointer",attrs:{"prepend-icon":"","placeholder":_vm.clearName(type.url)},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}],null,true),model:{value:(type.new_file),callback:function ($$v) {_vm.$set(type, "new_file", $$v)},expression:"type.new_file"}})],1),_c('td',[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","open-on-focus":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"colorBarrita",model:{value:(type.description),callback:function ($$v) {_vm.$set(type, "description", $$v)},expression:"type.description"}},on))]}}],null,true),model:{value:(type.menu),callback:function ($$v) {_vm.$set(type, "menu", $$v)},expression:"type.menu"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(type.description),expression:"type.description"}],ref:("textarea-" + i),refInFor:true,staticClass:"pa-2",staticStyle:{"width":"300px","height":"100px","color":"white"},attrs:{"outlined":"","autofocus":"","color":"primary"},domProps:{"value":(type.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(type, "description", $event.target.value)}}})])],1),_c('td',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.del(i)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("$delete")])],1)],1)])}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","outlined":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"30px","width":"100px"},attrs:{"outlined":""},on:{"click":function($event){_vm.dialog = false;
          _vm.$emit('update');
          _vm.fetch();}}},[_vm._v(_vm._s(_vm.$t("cancel", { name: "" })))]),_c('v-btn',{staticStyle:{"height":"30px","width":"100px !important"},attrs:{"elevation":"0"},on:{"click":_vm.save}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: "" }))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }